<template>
  <div class="container small">
    <div class="background-icon">
      <i class="fa-solid fa-gear icon-spin slow"></i>
    </div>
    <h2 class="text-align-center margin-bottom-30">Vores webshop er desværre ikke tilgængelig i øjeblikket</h2>
    <template v-if="type == 'closed'">
      <p class="text-align-center">Webshoppen kan benyttes igen fra kl. 06.00.</p>
    </template>
    <template v-else-if="type == 'error'">
      <p class="text-align-center">Ønsker du forsikringsrådgivning eller et tilbud?</p>
      <p class="text-align-center">Du er meget velkommen til at ringe direkte til vores Kundeservice på tlf. <a href="tel:70104222">7010 4222</a>. Vi har ingen telefonkø.</p>
      <p class="text-align-center">
        Vores Kundeservice har åbent: <br />
        Mandag-torsdag kl. 08.00-20.00 <br />
        Fredag kl. 08.00-16.00.
      </p>
    </template>
    <p class="text-align-center margin-bottom-30">Du kan læse mere om Aros Forsikring på vores hjemmeside.</p>
    <div class="button-flex-container justify-center">
      <a class="button" href="https://www.aros-forsikring.dk/">Læs mere</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Lukket",
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  beforeCreate() {
    // Get current time
    let hours = new Date().getHours();

    if (hours > 1 && hours < 6) {
      return;
    }

    let self = this;

    // Create api string
    let apiString = "/api/isAlive";

    self
      .axios({
        method: "get",
        url: apiString,
        params: {
          preventCache: self.dayjs().unix(),
        },
        headers: {
          "Content-Type": "application/json",
          "x-ws-key": process.env.VUE_APP_KEY,
          Authorization: `Bearer ${self.$store.state.token}`,
        },
      })
      .then(function (response) {
        // If application is not alive push to lunkket
        if (response.data) {
          self.$router.push({
            name: "Hjem",
          });
        }
      });
  },
};
</script>
